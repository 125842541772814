<template>
    <v-container fluid>
      <v-card>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>I Consume</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
  
          <v-data-table
            :headers="headers"
            :items="formattedData"
            class="elevation-1 mt-4"
          >
          </v-data-table>
        </v-card-text>
      </v-card>

    </v-container>
  </template>
  
  <script>
  import RestResource from "../../../services/dataServiceMobileV2";
  import moment from 'moment';
  const service = new RestResource();
  
  export default {
    data() {
      return {
        beverages: [],
        headers: [
          { text: "Email", value: "email" },
          { text: "User ID", value: "userId" },
          { text: "Submission Id", value: "_id" },
          { text: "Created At", value: "createdAt" },
        ],
      };
    },
  
    computed: {
      formattedData() {
        return this.beverages.map(data => ({
          ...data,
          createdAt: data.createdAt ? moment(data.createdAt).format('DD-MMM-YYYY') : 'Not Found'
        }));
      }
    },
  
    mounted() {
      this.getData();
    },
  
    methods: {
        getData() {
        this.$setLoader();
        service.getBeverageData().then((r) => {
          this.beverages = r.data.data;
          this.$disableLoader();
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .v-img.clickable {
    cursor: pointer;
  }
  </style>
  